<template>
  <div class="layout align">
    <loader v-if="loading == true"></loader>
    <div v-else class="contentBlock">
      <div class="title">
        <h3>Рекомендация на заём</h3>
      </div>
      <div class="body">
        <div v-if="activeDealData('DealInfo').Is_pdl === 1">
          <p id="smallIntro">Для того, чтобы получать по 3000р за каждый выданный займ,
            <a target="_blank" v-if="isAndroidCheck()"
              href="https://play.google.com/store/apps/details?id=com.finmoll.msk.partner">скачайте наше партнерское
              приложение</a>
            <a target="_blank" v-else href="https://lnd.msk.finmoll.com/partner/">скачайте наше партнерское приложение</a>
            и станьте нашим партнером
          </p>
          <div v-if="isAndroidCheck()">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.finmoll.msk.partner"
              id="androidLink">
              <img :src="'/img/ru_badge_web_generic.png'" alt="">
            </a>
          </div>
        </div>
        <div v-else>
          <p>Порекомендуйте нас друзьям и мы погасим Ваш следующий платеж</p>
        </div>
        <div id="addRecInptWrap">
          <div class="inputBox">
            <baseSelectDadata 
              v-model.trim="lastName.value" 
              label="Фамилия" 
              type="LAST_NAME" 
              :errorMsg="lastName.errorMsg"
              @focus="lastName.errorMsg = ''" 
              @blur="checkFio('lastName')" />
          </div>

          <div class="inputBox">
            <baseSelectDadata 
              v-model.trim="firstName.value" 
              label="Имя" 
              type="FIRST_NAME" 
              :errorMsg="firstName.errorMsg"
              @focus="firstName.errorMsg = ''" 
              @blur="checkFio('firstName')" />
          </div>

          <div class="inputBox">
            <baseSelectDadata 
              v-model.trim="secondName.value" 
              label="Отчество" 
              type="SECOND_NAME"
              :errorMsg="secondName.errorMsg" 
              @focus="secondName.errorMsg = ''" 
              @blur="checkFio('secondName')" />
          </div>

          <!-- todo убрать в компонент -->
          <div class="inputBox">
            <div class="inputBoxWrap" :class="{ errField: phoneNumber.errorMsg.length > 0 }">
              <input 
                v-model.trim="phoneNumber.value" 
                v-mask="'+7 (###) ###-##-##'"
                type="tel" 
                id="phoneNumber" 
                @focus="phoneNumber.errorMsg = ''"
                @blur="checkPhoneNumber()">
              <label for="phoneNumber">Телефон</label>
            </div>
            <div v-show="phoneNumber.errorMsg.length > 0" v-html="phoneNumber.errorMsg" class="errMsg"></div>
          </div>
        </div>

        <div class="inputBox">
          <baseSelectDadata 
            v-model.trim="residentialAddress.value" 
            label="Фактический адрес проживания"
            type="RESIDENTIAL_ADDRESS" 
            placeholder="начните печатать адрес и выберите из выпадающего списка"
            :errorMsg="residentialAddress.errorMsg" 
            @dadata="residentialAddress.json= $event" 
            @focus="residentialAddress.errorMsg = ''"
            @blur="onBlur()" />
        </div>
      </div>

      <div class="layoutFooter btnIsset">
        <i class="stxt">Нажимая кнопку "отправить заявку" я подтверждаю, что у меня имеется согласие владельца
          отправляемого телефонного номера на передачу его персональных данных третьим лицам</i>
        <button v-on:click="sendRecomendation">Отправить заявку</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isAndroid } from 'mobile-device-detect';
export default {
  name: "addRecomendation",
  data() {
    return {
      loading: false,
      lastName: {
        value: '',
        errorMsg: ''
      },
      firstName: {
        value: '',
        errorMsg: ''
      },
      secondName: {
        value: '',
        errorMsg: ''
      },
      phoneNumber: {
        value: '+7',
        errorMsg: ''
      },
      residentialAddress: {
        value: '',
        json: {},
        errorMsg: ''
      }
    }
  },
  components: {
    loader: () => import('@/components/other/loader'),
    baseSelectDadata: () => import('@/components/baseSelectDadata')
  },
  methods: {
    isAndroidCheck() {
      return isAndroid;
    },
    checkFio(fieldName) {
      if (this[fieldName].value.length === 0) {
        this[fieldName].errorMsg = 'Обязательное поле';
        if (fieldName == 'secondName' && this[fieldName] !== '-') {
          this[fieldName].errorMsg += '. Если отчества нет, то поставьте символ -';
        }
      }
    },
    checkPhoneNumber() {
      // todo убрать костыли, например завести файл с часто используемыми функциями и импортировать из него
      if (this.$bikeFunction.correctPhone(this.phoneNumber.value)) {
        this.phoneNumber.errorMsg = '';
        return true
      } else {
        this.phoneNumber.errorMsg = 'Некорректный номер телефона';
        return false
      }
    },
    sendRecomendation() {
      let errCount = 0;
      if (this.lastName.value == '') {
        this.lastName.errorMsg = 'Обязательное поле';
        errCount += 1;
      }
      if (this.firstName.value == '') {
        this.firstName.errorMsg = 'Обязательное поле';
        errCount += 1;
      }
      if (this.secondName.value == '') {
        this.secondName.errorMsg = 'Обязательное поле';
        errCount += 1;
      }
      if (!this.checkPhoneNumber()) {
        errCount += 1;
      }

      if (errCount == 0) {
        //todo оформить покрасышЭ
        this.loading = true;
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'fmpSession'
        })
          .then(res => {
            console.log('fmpsession')
            this.$external.universalRequest({
              externalType: 'zita',
              action: 'AddRecommendation',
              partnerUserID: res.partnerUserID,
              partnerSessionKey: res.partnerSessionKey,
              FirstName: this.firstName.value,
              LastName: this.lastName.value,
              MiddleName: this.secondName.value,
              PhoneNumber: this.phoneNumber.value,
              AddressText: this.residentialAddress.value,
              AddressJSON: this.residentialAddress.json,
              clientSource: '',
            }).then(res => {
              this.firstName.value = '';
              this.lastName.value = '';
              this.secondName.value = '';
              this.phoneNumber.value = '';
              this.residentialAddress.value = '';
              this.residentialAddress.json = '';
              this.loading = false;
            }).catch(res => {
              if (res.errorCode > 0 || res.errorCode !== undefined) {
                alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
              }
            })
          }).catch(res => {
            if (res.errorCode > 0 || res.errorCode !== undefined) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
          });
      }

    },
    checkAddress() {
      if (this.residentialAddress.json.value == undefined) {
        this.residentialAddress.errorMsg = 'Адрес должен быть выбран из списка';
        return
      }

      if (this.residentialAddress.json.value != undefined && this.residentialAddress.value != this.residentialAddress.json.value) {
        this.residentialAddress.errorMsg = 'Адрес должен быть выбран из списка';
        return
      }
      try {
        if (this.residentialAddress.json.data.house == null) {
          this.residentialAddress.errorMsg = 'Адрес не полный, укажите город, улицу и номер дома';
          return
        }
      } catch (e) {
        console.log(e)
        return
      }

      if (this.residentialAddress.value == this.residentialAddress.json.value) {
        this.residentialAddress.errorMsg = '';
        return
      }
    },
    onBlur() {
      setTimeout(this.checkAddress, 300)
    }
  },

  computed: {
    ...mapGetters(['activeDealData',]),
  },

  watch: {
    'residentialAddress.json': {
      handler() {
        this.checkAddress()
      },
      deep: true
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    this.$myApi.checkVersion();
  }
}
</script>

<style >
  #autosuggest ul { width: 100%;border:1px solid #828282;}
  #autosuggest li {display: flex;align-items: center;padding: 2vw;}
  #autosuggest li:hover {cursor: pointer;}

  .autosuggest-container {display: flex;justify-content: center;}

  #autosuggest { width: 100%; display: block;}
  .autosuggest__results-item--highlighted {background: rgba(15, 87, 64, 0.11);}
  #smallIntro{font-size: 5vw;padding-bottom: 6vw}
  #androidLink{display: block;width: 100%;}
  #androidLink img{width: 80vw;margin: 0 auto 10vw;display: block}
  .layoutFooter { display: flex; flex-direction: column; }
  .inputBoxWrap input { margin-bottom: 0; width: calc(100% - 50px); }
  .errField input { border-color:#ff00003d; }

  @media screen and (min-width: 760px){
    #autosuggest li {padding: 1vw}
    #smallIntro{font-size: 2.4vw;padding-bottom: 3vw}
    #androidLink{display: block;width: 100%;}
    #androidLink img{width: 30vw;margin: 0 auto 3vw;}
    #addRecInptWrap{display: flex;align-items: flex-start;justify-content: space-between;flex-wrap: wrap}
    #addRecInptWrap > .inputBox{width: calc(50% - 1vw);}
    .inputBoxWrap input { margin-bottom: 0; width: calc(100% - 68px); }
  }
  @media screen and (min-width: 1200px){
    #autosuggest  li {padding: 5px;}
    #smallIntro{font-size: 16px;padding-bottom: 10px;}
    #androidLink{display: block;width: 100%;}
    #androidLink img{width: 300px;margin: 0 auto 10px;}
    #addRecInptWrap > .inputBox{width: calc(50% - 5px);}
    .layoutFooter { align-items: center;  }
  }
</style>
